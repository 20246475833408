import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import loadable from '@loadable/component'

const HubspotQuote = loadable(() => import('../common/hubspot-quote'))

const StyledH1 = styled.h1`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const PageServerSetupHeroSection = () => (
  <div style={{ display: "grid" }}>
    <StaticImage
      style={{
        gridArea: "1/1",
        minHeight: 400,
        maxHeight: 800
      }}
      width={1920}
      layout="constrained"
      alt=""
      src="../../images/header/hero-server-workstation-setup-services.jpg"
      placeholder='tracedSVG'
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        display: "grid",
      }}
    >
      <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto px-4'>
        <div className='w-full xl:w-1/2 flex flex-col mx-auto'>
          <StyledH1 className='text-3xl md:text-5xl font-extrabold text-white uppercase'>
            <span className='leading-tight'>Server and Workstation Setup and Maintenance</span>
          </StyledH1>
          <p className="text-xl text-white font-medium mt-4">Here at IT Proactive, we offer complete networking services from design and implementation to administrative support, allowing you to focus on running your business.</p>
        </div>
        <div className="hidden xl:block w-full xl:w-1/2 mx-auto">
          <HubspotQuote />
        </div>
      </div>
    </div>
  </div>
)

export default PageServerSetupHeroSection