import React from 'react'
import loadable from '@loadable/component'

const HubspotQuote = loadable(() => import('../common/hubspot-quote'))
const LiteYoutubeEmbedHere = loadable(() => import('../common/lite-youtube-embed-here'))

const PageServerSetupSection = () => (
  <>
    <div className="xl:hidden w-full xl:w-1/2 mx-auto">
      <HubspotQuote />
    </div>

    <div className="bg-white w-full border-b">
      <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto my-12">
        <div className='w-full lg:w-1/2 p-4'>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>Infrastructure Setup</h2>
          <p className='text-lg text-gray-800 mb-4'>Our core business is designing, implementing and maintaining Client/Server network infrastructure.</p>
          <p className='text-lg text-gray-800 mb-4'>Our team can assist with design/implementation of new or existing infrastructure, address server and application sprawl.</p>
          <p className='text-lg text-gray-800'>Our goal is more than just adding servers and workstations, it is to understand the needs of our clients and assist with recommendations of computer systems that help our clients grow and succeed.</p>
        </div>

        <div className='w-full lg:w-1/2 p-4'>
          <LiteYoutubeEmbedHere youtubeID="bKxWf_nTd4A" />
        </div>
      </div>
    </div>
  </>
)

export default PageServerSetupSection